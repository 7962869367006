import {validateCel, validateCpf, validateEmail} from "../../../utils/validate";

export default function validate(values, trocarSenha = 1) {
    let errors = {};

    if (!values.slug) {
        errors.escritorio = 'Informe o escritório';
    }

    if (!values.name) {
        errors.name = 'Informe o nome e sobrenome';
    }else{
        const arr = values.name.split(' ');
        if (arr.length < 2) {
            errors.name = 'Informe o nome e sobrenome';
        } else if(!arr[1]){
            errors.name = 'Informe o nome e sobrenome';
        }
    }

    /*if (!/\d{3}\.\d{3}\.\d{3}-\d{2}/.test(values.cpf)) {
        errors.cpf = 'Cpf Inválido';
    }*/

    if(!validateCpf(values.cpf)){
        errors.cpf = 'Cpf Inválido';
    }

    if (!values.email) {
        errors.email = 'Informe o e-mail';
    }else{
        if(!validateEmail(values.email)){
            errors.email = "E-mail inválido";
        }
    }

    if (!values.cel) {
        errors.cel = 'Informe o celular';
    }else{
        console.log(values.cel);
        console.log(validateCel(cel));
        if(!validateCel(values.cel)){
            errors.cel = "Celular inválido";
        }
    }

    if (!values.password && trocarSenha == 1) {
        errors.password = 'Informe a senha';
    }

    return errors;
};
