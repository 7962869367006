import React from "react";
import {MDBContainer} from "mdbreact";

function PublicLayout({children}){
    return(
        <MDBContainer fluid>
            {children}
        </MDBContainer>
    );
}

export default PublicLayout;