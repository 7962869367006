import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import "./index.css";
import logo from "./logo.png";
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter as Router } from "react-router-dom";
import Routes from './Routes'
import {AuthProvider} from "./store/auth";

class App extends Component {
  render() {
    return (
      <Router>
        <AuthProvider>
            <Routes />
        </AuthProvider>
      </Router>
    );
  }
}

export default App;
