import React from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
import { MDBBox } from 'mdbreact';

function PrivateLayout({children}){
    return(
        <div>
            <div>
                <Header/>
            </div>
            <div style={{position:'relative', height:'calc(100vh - 70px'}}>
                <Sidebar>
                    {children}
                </Sidebar>
            </div>
        </div>

    );
}

export default PrivateLayout;
