import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow
} from "mdbreact";
import {SingleSelect} from "react-select-material-ui";
import { optionsSexo, optionsEscolaridade, optionsEstadoCivil } from './options';
import api from "../../../services/api";
import CreateButton from "../../../components/Buttons/CreateButton";
import validate from "./validate";
import Notify from "../../../components/Notify";

const Insert = () => {

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({titulo: '', descricao: '', valor: ''});
    const [notify, setNotify] = useState({type:null, text:null});

    useEffect(() => {
        console.log(errors);
        if (Object.keys (errors).length === 0 && saving) {
            save();
        }
        setSaving(false);
    }, [errors]);

    function handleValidate(){
        setErrors(validate(form));
        setSaving(true);
    }

    function handleForm(event){
        const { value, id } = event.target;
        setForm({
            ...form,
            [id]: value
        });
    }

    function handleValor(event){
        let value = maskValor(event.target.value);

        setForm({
            ...form,
            valor: value
        })
    }

    function maskValor(value){
        if(!value){
            return value;
        }
        value = value.replace(/\D/g, "");
        value = parseFloat(value/100);

        return value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.")
    }

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }


    async function save(){
        try{
            const result = await api.post('/api/plano', {plano: form});
            console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            setSaving(false)
            setForm({titulo: '', descricao: ''});
            handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <Notify notify={notify} />
                    <form action="" className="needs-validation" onSubmit={submitHandler} noValidate>
                        <MDBRow>
                            <MDBCol md="12">
                                <MDBInput label="Título" id="titulo" name="titulo"
                                          onChange={handleForm}
                                          containerClass={errors.nome ? "invalid-input" : ""}
                                          value={form.titulo}
                                />
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="Descrição" id="descricao" name="descricao" onChange={handleForm} value={form.descricao} />
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="Valor" id="valor" name="valor" onChange={handleValor} value={form.valor} />
                            </MDBCol>
                            <MDBCol md="4" sm="4" xs="12">
                                <MDBInput type="number" label="Clientes por mês" id="clientes" name="clientes" onChange={handleForm} value={form.clientes} />
                            </MDBCol>
                            <MDBCol md="4" sm="4" xs="12">
                                <MDBInput type="number"  label="Cálculos por mês" id="calculos" name="calculos" onChange={handleForm} value={form.calculos} />
                            </MDBCol>
                            <MDBCol md="4" sm="4" xs="12">
                                <MDBInput type="number"  label="GB Arquivos" id="gb" name="gb" onChange={handleForm} value={form.gb} />
                            </MDBCol>
                        </MDBRow>
                        <hr/>
                        <MDBRow>
                            <MDBCol className="text-right">
                                <div style={{display: (saving ? 'none' : '')}}>
                                    <CreateButton onClick={handleValidate} />
                                </div>
                                <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                                    <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Salvar
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Insert;
