import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow
} from "mdbreact";
import {SingleSelect} from "react-select-material-ui";
import { optionsSexo, optionsEscolaridade, optionsEstadoCivil } from './options';
import api from "../../../services/api";
import CreateButton from "../../../components/Buttons/CreateButton";
import validate from "./validate";
import Notify from "../../../components/Notify";

const Insert = () => {

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({titulo: '', descricao: ''});
    const [notify, setNotify] = useState({type:null, text:null});
    const [mesAnoTabela, setMesAnoTabela] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
        console.log(errors);
        if (Object.keys(errors).length === 0 && saving) {
            save();
        }
        setSaving(false);
    }, [errors]);

    function handleValidate(){
        setErrors(validate(form));
        setSaving(true);
    }

    function handleForm(event){
        const { value, id } = event.target;
        setForm({
            ...form,
            [id]: value
        });
    }

    function handleSexo(value){
        setForm({
            ...form,
            sexo: value
        })
    }

    function handleEstadoCivil(value){
        setForm({
            ...form,
            estado_civil: value
        })
    }

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }


    async function save(){
        try{
            const result = await api.post('/api/indice-salario', {indiceSalario: form});
            console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            setSaving(false)
            setForm({titulo: '', descricao: ''});
            handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    function handleMesAno(event){
        setMesAnoTabela(formatDate(event.target.value));
    }

    function formatDate(value){
        if(!value){
            return value;
        }
        value = value.replace(/\D/g, "");
        value = ("000000"+value).slice(-6);
        let result = value.replace(/(\d{2})(\d{4})/g, "$1/$2");
        console.log(result);
        return result;
    }

    function selectFile(){
        setFile(null);
        document.getElementById('file').value = null;
        document.getElementById('file').click()
    }

    function handleFile(e){
        setFile(e.target.files[0]);
    }

    useEffect(() => {
        //console.log('cnis alterado', cnisFile);
        if(file){
            importFile();
        }
    }, [file]);

    async function importFile(){
        setSaving(true);
        try{
            const formData = new FormData();
            formData.append(
                "file",
                file,
                file.name
            );
            formData.append('mes_ano_tabela', mesAnoTabela);

            console.log(mesAnoTabela);
            const result = await api.post('/api/admin-import-indices-salarios', formData);
            console.log(result)
            setSaving(false);
            handleNotify({type: 'success', text: 'Dados Importados'});
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }


    }


    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <Notify notify={notify} />
                    <form action="" className="needs-validation" onSubmit={submitHandler} noValidate>
                        <MDBRow>
                            <MDBCol size="3">
                                <div className="div-md-form">
                                    <MDBInput
                                        label="Mês/Ano Tabela"
                                        id="mesAnoTabela"
                                        name="mesAnoTabela"
                                        type="text"
                                        onChange={handleMesAno}
                                        value={formatDate(mesAnoTabela)}
                                    />
                                </div>
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBBtn style={{display: (saving ? 'none' : '')}} onClick={selectFile}>
                                    <MDBIcon icon="upload" />&nbsp;&nbsp;&nbsp;Selecionar Arquivo
                                </MDBBtn>
                                <MDBBtn style={{display: (saving ? '' : 'none')}} outline disabled >
                                    <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Importando
                                </MDBBtn>
                                <input style={{display: 'none'}} type="file" id="file" onChange={handleFile} />
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Insert;
