import React, {createContext, useEffect, useState} from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBIcon, MDBAlert
} from "mdbreact";
import Card from "./card";
import Chart from "./chart";
import PageHeader from "../../../components/PageHeader";
import api from "../../../services/api";
import PlanosExpirados from "./planosExpirados";
import {meses} from "../../../utils/meses";
import useWindowDimensions from "../../../components/hooks/useWindowDimensions";

const Dashboard = () => {

    const [loadingTotalUsuarios, setLoadingTotalUsuarios] = useState(false);
    const [loadingTotalUsuariosMes, setLoadingTotalUsuariosMes] = useState(false);
    const [loadingTotalUsuariosMeses, setLoadingTotalUsuariosMeses] = useState(false);
    const [loadingTotalCalculos, setLoadingTotalCalculos] = useState(false);
    const [loadingTotalCalculosMes, setLoadingTotalCalculosMes] = useState(false);
    const [loadingTotalCalculosMeses, setLoadingTotalCalculosMeses] = useState(false);
    const [totalCalculos, setTotalCalculos] = useState(0);
    const [totalCalculosMes, setTotalCalculosMes] = useState(0);
    const [totalCalculosMeses, setTotalCalculosMeses] = useState([]);
    const [labelsCalculos, setLabelsCalculos] = useState(['a', 'b', 'c', 'd', 'e', 'f']);
    const [dataCalculos, setDataCalculos] = useState([10,20,15,8,30,45]);
    const [totalUsuarios, setTotalUsuarios] = useState(0);
    const [totalUsuariosMes, setTotalUsuariosMes] = useState(0);
    const [totalUsuariosMeses, setTotalUsuariosMeses] = useState([]);
    const [labelsUsuarios, setLabelsUsuarios] = useState(['a', 'b', 'c', 'd', 'e', 'f']);
    const [dataUsuarios, setDataUsuarios] = useState([10,20,15,8,30,45]);
    const [mes, setMes] = useState('');
    const { height, width } = useWindowDimensions();
    const [verificarIndices, setVerificarIndices] = useState({});
    const [verificarSobrevidas, setVerificarSobrevidas] = useState({});

    useEffect(() => {
        let mes = '';
        getTotalUsuarios();
        getTotalUsuariosMes();
        getTotalUsuariosMeses(6);
        getTotalCalculos();
        getTotalCalculosMes();
        getTotalCalculosMeses(6);
        verificarImportacaoIndiceSalario();
        verificarImportacaoSobrevida();
    }, []);


    async function getTotalUsuarios(){
        setLoadingTotalUsuarios(true);
        const result = await api.get('/api/get-total-usuarios');
        setTotalUsuarios(result.data);
        setLoadingTotalUsuarios(false);
    }


    async function getTotalUsuariosMes(){
        let date = new Date();
        setMes(meses[date.getMonth()].extenso);
        setLoadingTotalUsuarios(true);
        const result = await api.get('/api/get-total-usuarios-mes');
        setTotalUsuariosMes(result.data);
        setLoadingTotalUsuariosMes(false);
    }

    async function getTotalUsuariosMeses(qtd){
        setLoadingTotalUsuarios(true);
        const result = await api.get('/api/get-total-usuarios-meses/'+qtd);
        console.log(result.data);
        let newTotalUsuariosMeses = result.data;
        let newLabels = [];
        let newData = [];
        for(let i in newTotalUsuariosMeses){
            newLabels.push(meses[newTotalUsuariosMeses[i].month-1].abrev+'/'+newTotalUsuariosMeses[i].year);
            newData.push(newTotalUsuariosMeses[i].qtd);
        }
        setLabelsUsuarios(newLabels);
        setDataUsuarios(newData);
        setTotalUsuariosMeses(newTotalUsuariosMeses);
        setLoadingTotalUsuariosMeses(false);
    }

    async function getTotalCalculos(){
        setLoadingTotalCalculos(true);
        const result = await api.get('/api/get-total-calculos');
        setTotalCalculos(result.data);
        setLoadingTotalCalculos(false);
    }

    async function getTotalCalculosMes(){
        let date = new Date();
        setMes(meses[date.getMonth()].extenso);
        setLoadingTotalCalculos(true);
        const result = await api.get('/api/get-total-calculos-mes');
        setTotalCalculosMes(result.data);
        setLoadingTotalCalculos(false);
    }

    async function getTotalCalculosMeses(qtd){
        setLoadingTotalUsuarios(true);
        const result = await api.get('/api/get-total-calculos-meses/'+qtd);
        let newTotalCalculosMeses = result.data;
        let newLabels = [];
        let newData = [];
        for(let i in newTotalCalculosMeses){
            newLabels.push(meses[newTotalCalculosMeses[i].month-1].abrev+'/'+newTotalCalculosMeses[i].year);
            newData.push(newTotalCalculosMeses[i].qtd);
        }
        setLabelsCalculos(newLabels);
        setDataCalculos(newData);
        setTotalCalculosMeses(newTotalCalculosMeses);
        setLoadingTotalCalculosMeses(false);
    }

    async function verificarImportacaoIndiceSalario(){
        const result = await api.get('/api/verificar-importacao-indice-salario');
        setVerificarIndices(result.data);
    }

    async function verificarImportacaoSobrevida(){
        const result = await api.get('/api/verificar-importacao-sobrevida');
        setVerificarSobrevidas(result.data);
    }

    return(
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol>
                    <PageHeader title="Dashboard" />
                </MDBCol>
            </MDBRow>
            {
                verificarIndices.resposta ? (
                    <MDBRow>
                        <MDBCol>
                            <br/>
                            <MDBAlert color="warning">
                                Necessário realizar importação de índices de salários.
                                A última importação ser refere a tabela {verificarIndices.ultima_importacao}
                            </MDBAlert>
                        </MDBCol>
                    </MDBRow>
                ) : null
            }
            {
                verificarSobrevidas.resposta ? (
                    <MDBRow>
                        <MDBCol>
                            <br/>
                            <MDBAlert color="warning">
                                Necessário realizar importação de sobrevidas.
                                A última importação ser refere ao ano de {verificarSobrevidas.ultima_importacao}
                            </MDBAlert>
                        </MDBCol>
                    </MDBRow>
                ) : null
            }
            <MDBRow style={{marginTop: '1.5rem'}}>
                <MDBCol xl="12" lg="12" md="12">
                    <PlanosExpirados />
                </MDBCol>
                <MDBCol xl="6" lg="6" md="12">
                    {/*{height} x {width}*/}
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol lg="3" sm="12">
                    {Card({title: 'Cálculos', content: totalCalculos})}
                </MDBCol>
                <MDBCol lg="3" sm="12">
                    {Card({title: 'Cálculos '+mes, content: totalCalculosMes})}
                </MDBCol>
                <MDBCol lg="3" sm="12">
                    {Card({title: 'Usuários', content: totalUsuarios})}
                </MDBCol>
                <MDBCol lg="3" sm="12">
                    {Card({title: 'Usuários '+mes, content: totalUsuariosMes})}
                </MDBCol>
                <MDBCol lg="6" sm="12">
                    {
                        totalCalculosMeses.length > 0 ? (
                            <Chart title="Cálculos" label="Cálculos dos últimos 6 meses" labels={labelsCalculos} data={dataCalculos} />
                        ) : null
                    }
                </MDBCol>
                <MDBCol lg="6" sm="12">
                    {
                        totalCalculosMeses.length > 0 ? (
                            <Chart title="Usuários" label="Usuários dos últimos 6 meses" labels={labelsUsuarios} data={dataUsuarios} />
                        ) : null
                    }
                </MDBCol>
            </MDBRow>
            <br/><br/>
        </MDBContainer>
    );
}

export default Dashboard;
