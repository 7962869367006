export default function validate(values) {
    let errors = {};

    if (!values.mes_ano) {
        errors.mes_ano = 'Informe o Mês/Ano';
    }

    if (!values.moeda) {
        errors.moeda = 'Informe a moeda';
    }

    return errors;
};
