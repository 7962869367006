import React, {createContext, useEffect, useState} from "react";
import api from "../services/api";
import { useHistory } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [msgLogin, setMsgLogin] = useState(null);
    const [processing, setProcessing] = useState();
    const history = useHistory();

    useEffect(() => {
        const storagedUser = localStorage.getItem('@App:user');
        const storagedToken = localStorage.getItem('@App:token');
        //console.log(storagedToken, storagedUser);
        if (storagedToken !== "undefined" && storagedUser !== "undefined") {
            console.log("===================");
            setUser(JSON.parse(storagedUser));
            api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
        }
    }, []);

    async function Login(email, password){
        setProcessing(true);
        setMsgLogin(null);
        try{
            const response = await api.post('/oauth/token', {
                grant_type: 'password',
                client_id: 3,
                client_secret: 'Ld6Dv0H27guVVatHmPBTLnpYNQNb3o01iL5ra2Ns',
                username: email,
                password: password,
                scope: ''
            });

            if(response.status === 200){
                const token = response.data.access_token;
                api.defaults.headers.Authorization = `Bearer ${token}`;
                //console.log('api', api.defaults.headers.Authorization);
                const responseUser = await api.get('/api/auth-user-admin');
                //console.log(responseUser);
                setUser(responseUser.data)
                localStorage.setItem('@App:user', JSON.stringify(responseUser.data));
                localStorage.setItem('@App:token', token);
                history.push("/dashboard");
            }
        }catch (error){
            if(error.response.status === 400){
                console.log('login inválido');
                setMsgLogin('login inválido');
            }
        }
        setProcessing(false);
    }

    function Logout() {
        setUser(null);

        localStorage.removeItem('@App:user');
        localStorage.removeItem('@App:token');
        history.push("/login");
    }

    return (
        <AuthContext.Provider value={{signed: Boolean(user), user, msgLogin, processing, Login, Logout}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;


