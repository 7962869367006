import React, {useEffect, useState} from 'react';
import {MDBBtn, MDBIcon} from "mdbreact";

const SaveSmallButton = (props) => {

    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setSaving(props.saving)
    }, [props.saving]);

    return (
        <div>
            <MDBIcon size="2x" icon="save" style={{display: (saving ? 'none' : ''), cursor: 'pointer'}} onClick={props.onClick}/>
            <MDBIcon size="2x" disabled style={{display: (saving ? '' : 'none')}} icon="spinner" spin />
        </div>

    );
};

export default SaveSmallButton;

