import React, {useEffect, useState} from "react";
import { BrowserRouter as Router } from "react-router-dom";
//import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import styled from 'styled-components';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from './StyledSideNav';
import { Route, Switch} from 'react-router-dom';
import useWindowDimensions from "../../hooks/useWindowDimensions";

function Sidebar({children}){

    const [expanded, setExpanded] = useState(true);
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if(width < 700){
            setExpanded(false);
        }
    }, [width]);

    return(
        <Router>
            <Route render={({ location, history }) => (
                <React.Fragment>
                    <SideNav
                        expanded={expanded}
                        onSelect={(selected) => {
                            const to = '/' + selected;
                            if (location.pathname !== to) {
                                history.push(to);
                            }
                        }}
                        onToggle={() => setExpanded(!expanded)}
                    >

                        <Nav defaultSelected="dashboard">
                            <NavItem eventKey="dashboard">
                                <NavIcon>
                                    <i className="fa fa-fw fa-tachometer-alt"/>
                                </NavIcon>
                                <NavText>
                                    Dashboard
                                </NavText>
                            </NavItem>
                            {/*<NavItem eventKey="admin-roles">
                                <NavIcon>
                                    <i className="fa fa-fw fa-boxes"/>
                                </NavIcon>
                                <NavText>
                                    Tipos de Admins
                                </NavText>
                            </NavItem>*/}
                            <NavItem eventKey="admins">
                                <NavIcon>
                                    <i className="fa fa-fw fa-boxes"/>
                                </NavIcon>
                                <NavText>
                                    Admins
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="site">
                                <NavIcon>
                                    <i className="fa fa-fw fa-boxes"/>
                                </NavIcon>
                                <NavText>
                                    Site
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="planos">
                                <NavIcon>
                                    <i className="fa fa-fw fa-boxes"/>
                                </NavIcon>
                                <NavText>
                                    Planos
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="usuarios">
                                <NavIcon>
                                    <i className="fa fa-fw fa-users"/>
                                </NavIcon>
                                <NavText>
                                    Usuários
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="categorias">
                                <NavIcon>
                                    <i className="fa fa-fw fa-cubes"/>
                                </NavIcon>
                                <NavText>
                                    Categorias
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="conteudos">
                                <NavIcon>
                                    <i className="fa fa-fw fa-file-alt"/>
                                </NavIcon>
                                <NavText>
                                    Conteúdos
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="indices-salarios">
                                <NavIcon>
                                    <i className="fa fa-fw fa-chart-line"/>
                                </NavIcon>
                                <NavText>
                                    Índices Salários
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="salarios-minimos">
                                <NavIcon>
                                    <i className="fa fa-fw fa-chart-line"/>
                                </NavIcon>
                                <NavText>
                                    Salários Mínimos
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="salarios-tetos">
                                <NavIcon>
                                    <i className="fa fa-fw fa-chart-line"/>
                                </NavIcon>
                                <NavText>
                                    Salários Tetos
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="sobrevidas">
                                <NavIcon>
                                    <i className="fa fa-fw fa-heartbeat"/>
                                </NavIcon>
                                <NavText>
                                    Sobrevida
                                </NavText>
                            </NavItem>
                        </Nav>
                        {/*<Toggle/>*/}
                        <Toggle className="my-analytics-toggle" >
                            {expanded ? (
                                <i className="fa fa-outdent" aria-hidden="true"/>
                            ) : (
                                <i className="fa fa-indent" aria-hidden="true"/>
                            )}
                        </Toggle>
                    </SideNav>
                    <main style={{marginLeft: (expanded ? '240px' : '65px')}}>
                        {children}
                        {/*<Route path="/" exact component={props => <RootComponent />} />*/}
                        {/*<Route path="/home" component={props => <Home />} />*/}
                        {/*<Route path="/devices" component={props => <Devices />} />*/}
                    </main>
                </React.Fragment>
            )}
            />
        </Router>
    );
}

export default Sidebar;


