import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow
} from "mdbreact";
import {SingleSelect} from "react-select-material-ui";
import { optionsSexo, optionsEscolaridade, optionsEstadoCivil } from './options';
import api from "../../../services/api";
import CreateButton from "../../../components/Buttons/CreateButton";
import validate from "./validate";
import Notify from "../../../components/Notify";
import {maskCel, maskCpf} from "../../../utils/mask";

const Insert = () => {

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({periodicidade: 1});
    const [notify, setNotify] = useState({type:null, text:null});
    const [showPassword, setShowPassword] = useState(false);
    const [optionsPlanos, setOptionsPlanos] = useState([]);

    useEffect(() => {
        getPlanos();
    }, []);

    useEffect(() => {
        console.log(errors);
        if (Object.keys(errors).length === 0 && saving) {
            save();
        }
        setSaving(false);
    }, [errors]);

    function handleValidate(){
        setErrors(validate(form));
        setSaving(true);
    }

    function handleForm(event){
        let { value, id } = event.target;

        if(id === 'cpf'){
            value = maskCpf(value);
        }

        if(id === 'cel'){
            value = maskCel(value);
        }

        let newForm = {
            ...form,
            [id]: value
        };

        if(id === 'escritorio'){
            let newSlug = value.replace(/[^a-zA-Z- ]/g,'');
            newSlug = newSlug.toLowerCase();
            newSlug = newSlug.replace(/ /g, '-');
            newForm = {
                ...newForm,
                slug: newSlug
            }
        }

        setForm(newForm);
        setErrors(validate(newForm));
    }

    function handlePlano(value){
        console.log(value);
        setForm({
            ...form,
            plano_id: value
        })
    }

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }


    async function save(){
        try{
            const result = await api.post('/api/user', {user: form});
            //console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            setSaving(false);
            handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    async function getPlanos(){
        try{
            const result = await api.get('/api/get-planos-for-options');
            console.log(result.data);
            if(!result.data.valid){
                setOptionsPlanos(result.data);
                let newForm = {
                    ...form,
                    plano_id: result.data[0].value
                }
                setForm(newForm);
            }
        }catch (error){
            console.log(error);
        }
    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };


    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <Notify notify={notify} />
                    <form action="" className="needs-validation" onSubmit={submitHandler} noValidate autoComplete="off">
                        <MDBRow>
                            <MDBCol md="12">
                                <SingleSelect
                                    label="Plano"
                                    options={optionsPlanos}
                                    id="plano"
                                    name="plano"
                                    onChange={handlePlano}
                                    value={form.plano_id}
                                />
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="Escritório" id="escritorio" name="escritorio"
                                          onChange={handleForm}
                                          containerClass={errors.name ? "invalid-input" : ""}
                                />
                                {/*<div>{form.slug}</div>*/}
                                <div className="erro-campo-invalido">{errors.escritorio}</div>
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="Nome Completo" id="name" name="name"
                                          onChange={handleForm}
                                          containerClass={errors.name ? "invalid-input" : ""}
                                />
                                <div className="erro-campo-invalido">{errors.name}</div>
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="CPF" id="cpf" name="cpf"
                                          onChange={handleForm}
                                          containerClass={errors.cpf ? "invalid-input" : ""}
                                          value={form.cpf}
                                />
                                <div className="erro-campo-invalido">{errors.cpf}</div>
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="E-mail" id="email" name="email"
                                          onChange={handleForm}
                                          containerClass={errors.email ? "invalid-input" : ""}
                                />
                                <div className="erro-campo-invalido">{errors.email}</div>
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="Celular" id="cel" name="cel"
                                          onChange={handleForm}
                                          containerClass={errors.cel ? "invalid-input" : ""}
                                          value={form.cel}
                                />
                                <div className="erro-campo-invalido">{errors.cel}</div>
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBRow>
                                    <MDBCol md="11" sm="11" xs="11">
                                        <MDBInput label="Senha" id="password" name="password"
                                                  type={showPassword ? "text" : "password"}
                                                  onChange={handleForm}
                                                  containerClass={errors.password ? "invalid-input" : ""}
                                                  autoComplete="new-password"
                                        />
                                        <div className="erro-campo-invalido">{errors.password}</div>
                                    </MDBCol>
                                    <MDBCol md="1" sm="1" xs="1">
                                        <i className={"fa "+(showPassword ? "fa-eye" : "fa-eye-slash")}
                                           style={{cursor: "pointer", marginTop: "45px"}}
                                           onClick={() => setShowPassword(!showPassword)}
                                        />
                                    </MDBCol>

                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <hr/>
                        <MDBRow>
                            <MDBCol className="text-right">
                                <div style={{display: (saving ? 'none' : '')}}>
                                    <CreateButton onClick={handleValidate} />
                                </div>
                                <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                                    <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Salvar
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Insert;
