import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBContainer, MDBFormInline, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdbreact";
import {Line} from "react-chartjs-2";
import api from "../../../services/api";
import EditButton from "../../../components/Buttons/EditButton";
import calculateAge from "../../../utils/date";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import List from "../../../components/List";

const ListSalarioMinimo = (props) => {

    const head = ['Id', 'Mes/Ano', 'Moeda', 'Salário', ''];
    const columns = ['id', 'mes_ano', 'moeda', 'salario'];
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [search, setSearch] = useState(null);

    useEffect(() => {
        list();
    }, []);

    async function list(search = ''){
        setLoading(true);
        try{
            const result = await api.get('/api/salario-minimo', {params: {search: search}});
            let items = result.data;
            for(let i in items){
                items[i].idade = calculateAge(items[i].nascimento);
            }
            setItems(items)
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
        }
    }

    function edit(id){
        console.log(id);
        props.edit(id);
    }

    function askDelete(item){
        setDeleteTitle(item.mes_ano);
        setDeleteId(item.id);
        setDeleteModal(true);
    }

    async function deleteItem(id){
        setDeleteLoading(true);
        try{
            const result = await api.delete('/api/salario-minimo/'+id);
            //setItems(items)
            setDeleteLoading(false);
            setDeleteModal(false);
            list();
        }catch (error){
            console.log(error);
            setDeleteLoading(false);
        }
    }

    function validateSearch(value){
        let arrValue = value.split('/');
        return arrValue[0] !== '00' && parseInt(arrValue[0]) <= 12 && parseInt(arrValue[1]) > 1900;
    }

    function formatSearch(value){
        if(!value){
            return value;
        }
        value = value.replace(/\D/g, "");
        value = ("000000"+value).slice(-6);
        value = value.replace(/(\d{2})(\d{4})/g, "$1/$2");
        return value;
    }

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBModal isOpen={deleteModal} centered>
                <MDBModalHeader>Excluir</MDBModalHeader>
                <MDBModalBody>
                    Deseja excluir o item {deleteTitle}?
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="default" onClick={() => setDeleteModal(false)}>Não</MDBBtn>
                    <MDBBtn color="danger" onClick={() => deleteItem(deleteId)}>Sim</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
            <MDBCard>
                <MDBCardBody>
                    <List
                        items={items}
                        head={head}
                        columns={columns}
                        loading={loading}
                        list={list}
                        edit={edit}
                        askDelete={askDelete}
                        showDelete={true}
                        showEdit={true}
                        placeholder={"mes/ano"}
                        formatSearch={formatSearch}
                        validateSearch={validateSearch}
                    />
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default ListSalarioMinimo;
