import {validateCel, validateCpf, validateEmail} from "../../../utils/validate";

export default function validate(values) {
    let errors = {};

    if (!values.categoria_id) {
        errors.categoria_id = 'Informe a categoria';
    }

    if (!values.titulo) {
        errors.titulo = 'Informe o título';
    }

    if (!values.descricao) {
        errors.descricao = 'Informe a descricao';
    }

    return errors;
};
