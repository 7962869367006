import React, {useState} from 'react';
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBIcon, MDBRow, MDBCol, MDBContainer} from "mdbreact";
import {Link} from "react-router-dom";

const Card = (card) => {

    const [btnAdd, setBtnAdd] = useState(false);

    return (
        <MDBContainer style={{ marginTop: "1.5rem"}}>
            <MDBCard>
                <MDBCardHeader className="grey lighten-3" tag="h5">
                    <MDBRow>
                        <MDBCol className="custom-primary-color-text">
                            {card.title}
                        </MDBCol>
                        {/*<MDBCol className="text-right" style={{display: btnAdd ? '' : 'none'}}>
                            <Link to={card.add}>
                                <MDBIcon icon="plus-circle" size="lg" className="custom-primary-color-text" />
                            </Link>
                        </MDBCol>*/}
                    </MDBRow>

                </MDBCardHeader>
                <MDBCardBody className="text-center">
                    <MDBCardTitle tag="h1"><strong>{card.content}</strong></MDBCardTitle>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
}

export default Card;
