import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow
} from "mdbreact";
import {SingleSelect} from "react-select-material-ui";
import api from "../../../services/api";
import CreateButton from "../../../components/Buttons/CreateButton";
import validate from "./validate";
import Notify from "../../../components/Notify";
import {maskCel, maskCpf} from "../../../utils/mask";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {modules} from "./modules";

const Insert = () => {

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});
    const [notify, setNotify] = useState({type:null, text:null});
    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        getCategorias();
    }, []);

    useEffect(() => {
        console.log(errors);
        if (Object.keys(errors).length === 0 && saving) {
            save();
        }
        setSaving(false);
    }, [errors]);

    function handleValidate(){
        setErrors(validate(form));
        setSaving(true);
    }

    function handleForm(event){
        let { value, id } = event.target;
        console.log(value);
        let newForm = {
            ...form,
            [id]: value
        };

        setForm(newForm);
        setErrors(validate(newForm));
    }

    function handleDescricao(value){
        console.log(value);
        let newForm = {
            ...form,
            descricao: value
        };
        console.log('form', newForm);
        setForm(newForm)

    }

    function handleCategoria(value){
        setForm({
            ...form,
            categoria_id: value
        })
    }

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }

    async function getCategorias(){
        const result = await api.get('/api/categoria');
        let categorias = result.data;
        let options = [];
        for(let i in categorias){
            options.push(
                {value: categorias[i].id, label: categorias[i].titulo}
            );
        }
        setCategorias(options);
    }


    async function save(){
        try{
            const result = await api.post('/api/conteudo', {conteudo: form});
            //console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            setSaving(false);
            handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <Notify notify={notify} />
                    <form action="" className="needs-validation" onSubmit={submitHandler} noValidate autoComplete="off">
                        <MDBRow>
                            <MDBCol md="12">
                                <SingleSelect
                                    label="Categoria"
                                    options={categorias}
                                    id="plano"
                                    name="plano"
                                    onChange={handleCategoria}
                                    value={form.categoria_id}
                                />
                                <div className="erro-campo-invalido">{errors.categoria_id}</div>
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="Titulo" id="titulo" name="titulo"
                                          onChange={handleForm}
                                          containerClass={errors.titulo ? "invalid-input" : ""}
                                />
                                <div className="erro-campo-invalido">{errors.titulo}</div>
                            </MDBCol>
                            <MDBCol>
                                <ReactQuill
                                    modules={modules}
                                    onChange={handleDescricao}
                                />
                                <div className="erro-campo-invalido">{errors.descricao}</div>
                            </MDBCol>
                            {/*<MDBCol md="12">
                                <textarea
                                    id="descricao" name="descricao"
                                    style={{width: '100%', fontSize: '14px'}}
                                    rows="30"
                                    onChange={handleForm}
                                />
                                <div className="erro-campo-invalido">{errors.descricao}</div>
                            </MDBCol>*/}
                        </MDBRow>
                        <hr/>
                        <MDBRow>
                            <MDBCol className="text-right">
                                <div style={{display: (saving ? 'none' : '')}}>
                                    <CreateButton onClick={handleValidate} />
                                </div>
                                <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                                    <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Salvar
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Insert;
