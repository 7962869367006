import {validateCel, validateCpf, validateEmail} from "../../../utils/validate";

export default function validate(values, trocarSenha = 1) {
    let errors = {};

    if (!values.titulo) {
        errors.titulo = 'Informe o título';
    }

    return errors;
};
