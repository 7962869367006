import React, {useState} from 'react';
import './style.css';
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import Home from "./home";
import PageHeader from "../../../components/PageHeader";
import ListPlano from "../Plano/list";

const Site = () => {

    const module = {singular: "Site", plural: "Sites", gender: 1}
    const [crudComponent, setCrudComponent] = useState(<Home/>);

    function showHome(){
        setCrudComponent(<Home/>);
    }

    return (
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol>
                    <PageHeader title="Site"/>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    {crudComponent}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default Site;