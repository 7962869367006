export default function validate(values) {
    let errors = {};

    if (!values.titulo) {
        errors.nome = 'Informe o titulo';
    }
    if (!values.descricao) {
        errors.nome = 'Informe a descricao';
    }
/*
    if (!/\d{3}\.\d{3}\.\d{3}-\d{2}/.test(values.cpf)) {
        errors.cpf = 'Cpf Inválido';
    }*/


    return errors;
};
