import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBContainer, MDBFormInline, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdbreact";
import {Line} from "react-chartjs-2";
import api from "../../../services/api";
import EditButton from "../../../components/Buttons/EditButton";
import calculateAge from "../../../utils/date";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import List from "../../../components/List";

const ListCliente = (props) => {

    const head = ['Id', 'Tipo'];
    const columns = ['id', 'name'];
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState(false);
    const [deleteId, setDeleteId] = useState(false);

    useEffect(() => {
        list();
    }, []);

    async function list(search = ''){
        setLoading(true);
        try{
            const result = await api.get('/api/admin-role', {params: {search: search}});
            let items = result.data;
            setItems(items)
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
        }
    }

    function edit(id){
        console.log(id);
        props.edit(id);
    }

    function askDelete(item){
        setDeleteTitle(item.title);
        setDeleteId(item.id);
        setDeleteModal(true);
    }

    async function deleteItem(id){
        setDeleteLoading(true);
        try{
            const result = await api.delete('/api/admin-role/'+id);
            //setItems(items)
            setDeleteLoading(false);
        }catch (error){
            console.log(error);
            setDeleteLoading(false);
        }
    }

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBModal isOpen={deleteModal} centered>
                <MDBModalHeader>Excluir</MDBModalHeader>
                <MDBModalBody>
                    Deseja excluir o item {deleteTitle}?
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="default" onClick={() => setDeleteModal(false)}>Não</MDBBtn>
                    <MDBBtn color="danger" onClick={() => deleteItem(deleteId)}>Sim</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
            <MDBCard>
                <MDBCardBody>
                    <List items={items} head={head} columns={columns} loading={loading} list={list} edit={edit} askDelete={askDelete} />
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default ListCliente;
