import React, {createContext, useEffect, useState} from 'react';
import {MDBBtn, MDBCard, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import PageHeader from "../../../components/PageHeader";
import ListPlanoUsuario from "./list";
import AddButton from "../../../components/Buttons/AddButton";
import ListButton from "../../../components/Buttons/ListButton";
import Insert from "./insert";
import Edit from "./edit";
import {useParams} from "react-router-dom/cjs/react-router-dom";

const PlanoUsuario = () => {

    const { userId } = useParams();
    const module = {singular: "Plano Usuario", plural: "Planos Usuarios", gender: 1}
    const [crudComponent, setCrudComponent] = useState(<ListPlanoUsuario edit={showEdit} userId={userId} />);
    const addButton = <AddButton module={module.singular} gender={module.gender} onClick={showInsert} />;
    const listButton = <ListButton module={module.plural} onClick={showList}/>;

    const [button, setButton] = useState(addButton);

    function showInsert(){
        console.log('show insert');
        setCrudComponent(<Insert userId={userId} />);
        setButton(listButton);
    }

    function showList(){
        setCrudComponent(<ListPlanoUsuario edit={showEdit} userId={userId} />);
        /*setButton({});*/
        setButton(addButton);
    }

    function showEdit(id){
        console.log('show edit');
        setCrudComponent(<Edit id={id} />);
        setButton(listButton);
    }

    return (
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol>
                    <PageHeader title={module.plural} button={button}/>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    {crudComponent}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};


export default PlanoUsuario;
