import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBContainer, MDBFormInline, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdbreact";
import {Line} from "react-chartjs-2";
import api from "../../../services/api";
import EditButton from "../../../components/Buttons/EditButton";
import calculateAge from "../../../utils/date";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import List from "../../../components/List";

const ListSobrevida = (props) => {

    const head = ['Id', 'Ano Sobrevida', 'Ano Fator', 'Idade', 'Sobrevida'];
    const columns = ['id', 'ano_sobrevida', 'ano_fator', 'idade', 'sobrevida'];
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [anoFator, setAnoFator] = useState('');


    useEffect(() => {
        list();
    }, []);

    async function list(search = ''){
        setLoading(true);
        if(!anoFator){
            setLoading(false);
            //return;
        }

        try{
            const result = await api.get('/api/sobrevida', {
                params: {
                    search: anoFator,
                    columns: [
                        {name: 'ano_fator', operator: '='},
                    ]
                }
            });
            //const result = await api.get('/api/sobrevida-by-mes-ano-tabela', {params: {ano_fator: anoFator}});
            let items = result.data;
            setItems(items)
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
        }
    }

    function edit(id){
        console.log(id);
        props.edit(id);
    }

    function askDelete(item){
        setDeleteTitle(item.title);
        setDeleteId(item.id);
        setDeleteModal(true);
    }

    async function deleteItem(id){
        setDeleteLoading(true);
        try{
            const result = await api.delete('/api/indice-salario/'+id);
            //setItems(items)
            setDeleteLoading(false);
        }catch (error){
            console.log(error);
            setDeleteLoading(false);
        }
    }

    function handleAnoFator(event){
        event.target.value = formatSearch(event.target.value);
        setAnoFator(event.target.value);
    }

    function formatSearch(value){
        if(!value){
            return value;
        }
        value = value.replace(/\D/g, "");
        return value.substr(0, 4);
    }


    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBModal isOpen={deleteModal} centered>
                <MDBModalHeader>Excluir</MDBModalHeader>
                <MDBModalBody>
                    Deseja excluir o item {deleteTitle}?
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="default" onClick={() => setDeleteModal(false)}>Não</MDBBtn>
                    <MDBBtn color="danger" onClick={() => deleteItem(deleteId)}>Sim</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
            <MDBCard>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol size="3">
                            <div className="div-md-form">
                                <MDBInput
                                    label="Ano Fator"
                                    id="anoFator"
                                    name="anoFator"
                                    type="text"
                                    onChange={handleAnoFator}
                                    value={anoFator}

                                />
                            </div>
                        </MDBCol>
                        <MDBCol size="4">
                            <MDBBtn  color="brown" className="px-4" onClick={() => list()}>
                                <MDBIcon icon="search" />&nbsp;&nbsp;&nbsp;&nbsp;Pesquisar
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                    <List
                        items={items}
                        head={head}
                        columns={columns}
                        loading={loading}
                        list={list}
                        edit={edit}
                        askDelete={askDelete}
                        showEdit={false}
                        showDelete={false}
                        showSearch={false}
                    />
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default ListSobrevida;
