import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow
} from "mdbreact";
import {SingleSelect} from "react-select-material-ui";
import {optionsSexo, optionsEscolaridade, optionsEstadoCivil, optionsMoeda} from './options';
import api from "../../../services/api";
import CreateButton from "../../../components/Buttons/CreateButton";
import validate from "./validate";
import Notify from "../../../components/Notify";

const Edit = (props) => {

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});
    const [notify, setNotify] = useState({type:null, text:null});

    useEffect(() => {
        get(props.id);
    }, [props]);

    useEffect(() => {
        console.log(errors);
        if (Object.keys(errors).length === 0 && saving) {
            save();
        }
        setSaving(false);
    }, [errors]);

    function handleValidate(){
        setErrors(validate(form));
        setSaving(true);
    }

    function handleForm(event){
        const { value, id } = event.target;
        setForm({
            ...form,
            [id]: value
        });
    }

    function handleMoeda(value){
        setForm({
            ...form,
            moeda: value
        })
    }

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }

    async function get(id){
        setLoading(true);
        try{
            const result = await api.get('/api/salario-minimo/'+id);
            console.log(result.data);
            setForm(result.data);
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    async function save(){
        try{
            const result = await api.put('/api/salario-minimo/'+props.id, {salarioMinimo: form});
            console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            setSaving(false);
            handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <Notify notify={notify} />
                    <div className="text-center" style={{display: (loading ? '' : 'none')}}>
                        <MDBIcon icon="spinner" spin size="3x"/>
                    </div>
                    <form action="" className="needs-validation" onSubmit={submitHandler} noValidate style={{display: (loading ? 'none' : '')}}>
                        <MDBRow>
                            <MDBCol md="4">
                                <MDBInput label="Mês/Ano" id="mes_ano" name="mes_ano"
                                          onChange={handleForm}
                                          containerClass={errors.mes_ano ? "invalid-input" : ""}
                                          value={form.mes_ano}
                                />
                            </MDBCol>
                            <MDBCol md="4">
                                <SingleSelect
                                    label="Moeda"
                                    options={optionsMoeda}
                                    onChange={handleMoeda}
                                    className={errors.moeda ? "invalid-select" : ""}
                                    value={form.moeda}
                                />
                            </MDBCol>
                            <MDBCol md="4">
                                <MDBInput label="Salario" id="salario" name="salario"
                                          onChange={handleForm}
                                          containerClass={errors.salario ? "invalid-input" : ""}
                                          value={form.salario}
                                />
                            </MDBCol>
                        </MDBRow>
                        <hr/>
                        <MDBRow>
                            <MDBCol className="text-right">
                                <div style={{display: (saving ? 'none' : '')}}>
                                    <CreateButton onClick={handleValidate} />
                                </div>
                                <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                                    <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Salvar
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Edit;
