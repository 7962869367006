export default function calculateAge(birthday) { // birthday is a date
    const dtBirthday = new Date(birthday);
    const ageDifMs = Date.now() - dtBirthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function formatDate(date){
    const arrayDate = date.split('-');
    return (arrayDate[2]+'/'+arrayDate[1]+'/'+arrayDate[0]);
}