export const optionsMoeda = [
    {value: '', label: "Selecione"},
    {value: 'Cr$', label: "Cr$"},
    {value: 'NCr$', label: "NCr$"},
    {value: 'Cz$', label: "Cz$"},
    {value: 'NCz$', label: "NCz$"},
    {value: 'CR$', label: "CR$"},
    {value: 'R$', label: "R$"},
];

