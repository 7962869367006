import React, {useEffect, useState} from 'react';
import {Slide, toast, ToastContainer} from "react-toastify";

const Notify = (props) => {

    const notifySuccess = () => toast.success("Gravado com sucesso!");
    const notifyError = () => toast.error("Ocorreu um erro!");
    const notifyWarning = (message) => toast.warn(() => {
        //let items = "Verifique os campos:";
        //for(let i in message){ items += " " + i; }

        let items = "";
        for(let i in message){
            items += message[i]+'\n';
        }
        return items;
    });

    useEffect(() => {
        console.log(props.notify);
        callNotify(props.notify);
    }, [props.notify]);

    function callNotify(notify){
        switch (notify.type){
            case 'success':
                notifySuccess();
                break
            case 'error':
                notifyError();
                break
            case 'warning':
                notifyWarning(notify.text);
                break
        }
    }

    return (
        <ToastContainer
            className="text-center"
            position="top-center"
            autoClose={3000}
            hideProgressBar={true}
            transition={Slide}
        />
    );
};

export default Notify;
