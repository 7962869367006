import React, {useEffect, useState} from 'react';
import {MDBCol, MDBRow} from "mdbreact";
import api from "../../../services/api";
import SaveSmallButton from "../../../components/Buttons/SaveSmallButton";
import {MDBIcon} from "../../../components/Buttons/DeleteButton/styles";
import Notify from "../../../components/Notify";

const Recursos = () => {

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState([{},{},{}]);
    const [notify, setNotify] = useState({type:null, text:null});
    const [recursos, setRecursos] = useState([
        {titulo: '', texto: ''},
        {titulo: '', texto: ''},
        {titulo: '', texto: ''},
    ]);

    useEffect(() => {
        list();
    }, []);

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }

    const handleRecurso = (campo) => (event) => {
        const { value, id } = event.target;
        let newRecursos = [...recursos];
        let idRecurso = parseInt(id.split('-')[2]);
        newRecursos.find((item, index) => {
            if(item.id === idRecurso){
                newRecursos[index] = {...recursos[index], [campo]: value}
            }
        });
        setRecursos(newRecursos);
    }

    async function list(search = ''){
        setLoading(true);
        try{
            const result = await api.get('/api/site-recurso', {params: {search: search}});
            let items = result.data;
            setRecursos(items);
            const newSaving = [...saving];
            for(let i in items){
                newSaving[i] = {...saving[i], id: items[i].id, saving: false}
            }
            setSaving(newSaving);
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
        }
    }

    async function save(data){
        const newSaving = [...saving];
        newSaving.find((item, index) => {
            if(item.id === data.id){
                newSaving[index] = {...saving[index], saving: true};
            }
        });
        setSaving(newSaving);
        try{
            const result = await api.put('/api/site-recurso/'+data.id, {siteRecurso: data});
            console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                newSaving.find((item, index) => {
                    if(item.id === data.id){
                        newSaving[index] = {...saving[index], saving: false};
                    }
                });
                setSaving(newSaving);
                return;
            }
            newSaving.find((item, index) => {
                if(item.id === data.id){
                    newSaving[index] = {...saving[index], saving: false};
                }
            });
            setSaving(newSaving);
            handleNotify({type: 'success', text: ''});
        }catch (error){
            handleNotify({type: 'error', text: ''});
            newSaving.find((item, index) => {
                if(item.id === data.id){
                    newSaving[index] = {...saving[index], saving: false};
                }
            });
            setSaving(newSaving);
        }
    }

    return (
        <div>
            <Notify notify={notify} />
            <MDBRow style={{display: loading ? '' : 'none'}}>
                <MDBCol className="text-center">
                    <MDBIcon icon="spinner" spin size="4x" />
                </MDBCol>
            </MDBRow>
            <MDBRow style={{display: loading ? 'none' : ''}}>
                {
                    recursos.map((item, key) => {
                        return (
                            <MDBCol key={'recurso'+key} className="text-right">
                                <input
                                    className="h3 text-center"
                                    style={{width: '100%'}}
                                    type="text"
                                    placeholder="Título"
                                    id={'recurso-titulo-'+item.id}
                                    onChange={handleRecurso('titulo')}
                                    defaultValue={item.titulo}
                                />
                                <br/><br/>
                                <textarea
                                    className="text text-center"
                                    style={{width: '100%'}}
                                    rows="3"
                                    placeholder="texto"
                                    id={'recurso-texto-'+item.id}
                                    onChange={handleRecurso('texto')}
                                    defaultValue={item.texto}
                                />
                                <br/>
                                <SaveSmallButton saving={saving[key].saving} onClick={() => save({
                                    id: item.id,
                                    titulo: item.titulo,
                                    texto: item.texto,
                                }, 'texto-principal-home')}/>
                            </MDBCol>
                        )
                    })
                }
            </MDBRow>
        </div>

    );
};

export default Recursos;