export default function validate(values) {
    let errors = {};

    if (!values.name) {
        errors.name = 'Informe o nome';
    }

    if (!values.email) {
        errors.email = 'Informe o email';
    }

    /*if (!values.password) {
        errors.password = 'Informe a senha';
    }*/

    /*if (!/\d{3}\.\d{3}\.\d{3}-\d{2}/.test(values.cpf)) {
        errors.cpf = 'Cpf Inválido';
    }*/

    return errors;
};
