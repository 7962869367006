import React, {useContext, useState} from "react";
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from "mdbreact";
import { BrowserRouter as Router } from 'react-router-dom';
import logo from "../../../assets/logo.png";
import AuthContext from "../../../store/auth";

function Header(){
    const [isOpen, setIsOpen] = useState(false);
    const context = useContext(AuthContext);

    function logout(){
        context.Logout();
    }

    return(
        <Router>
            <MDBNavbar className="z-depth-1-half" color="unique-color-dark" dark expand="md">
                <MDBNavbarBrand>
                    {/*<img src={logo} height="40" alt="logo"/>*/}
                    <strong className="white-text" style={{fontSize: "28px"}}>Admin - ICDS Prev</strong>
                </MDBNavbarBrand>
                <MDBNavbarNav right>
                    <MDBNavItem onClick={logout}>
                        <MDBNavLink to="#" onClick={logout}>Sair</MDBNavLink>
                    </MDBNavItem>
                </MDBNavbarNav>
            </MDBNavbar>
        </Router>
    );
}

export default Header;
