import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow
} from "mdbreact";
import {SingleSelect} from "react-select-material-ui";
import {optionsSexo, optionsEscolaridade, optionsEstadoCivil, periodicidades} from './options';
import api from "../../../services/api";
import CreateButton from "../../../components/Buttons/CreateButton";
import validate from "./validate";
import Notify from "../../../components/Notify";
import {maskCel, maskCpf} from "../../../utils/mask";

const Insert = (props) => {

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({user_id: props.userId, status: 1});
    const [notify, setNotify] = useState({type:null, text:null});
    const [showPassword, setShowPassword] = useState(false);
    const [optionsPlanos, setOptionsPlanos] = useState([]);

    useEffect(() => {
        getPlanos();
    }, []);

    useEffect(() => {
        console.log(errors);
        if (Object.keys(errors).length === 0 && saving) {
            save();
        }
        setSaving(false);
    }, [errors]);

    function handleValidate(){
        setErrors(validate(form));
        setSaving(true);
    }

    function handleForm(event){
        let { value, id } = event.target;

        let newForm = {
            ...form,
            [id]: value
        };

        setForm(newForm);
        setErrors(validate(newForm));
    }

    function handlePlano(value){
        //console.log(value);
        setForm({
            ...form,
            plano_id: value
        })
    }

    function handlePeriodicidade(value){
        setForm({
            ...form,
            periodicidade: value
        })
    }

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }


    async function save(){
        try{
            const result = await api.post('/api/plano-usuario', {planoUser: form});
            //console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            setSaving(false);
            handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    async function getPlanos(){
        try{
            const result = await api.get('/api/get-planos-for-options');
            console.log(result.data);
            if(!result.data.valid){
                setOptionsPlanos(result.data);
                let newForm = {
                    ...form,
                    plano_id: result.data[0].value
                }
                setForm(newForm);
            }
        }catch (error){
            console.log(error);
        }
    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };


    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <Notify notify={notify} />
                    <form action="" className="needs-validation" onSubmit={submitHandler} noValidate autoComplete="off">
                        <MDBRow>
                            <MDBCol sm="4" xs="12">
                                <SingleSelect
                                    label="Plano"
                                    options={optionsPlanos}
                                    id="plano"
                                    name="plano"
                                    onChange={handlePlano}
                                    value={form.plano_id}
                                />
                            </MDBCol>
                            <MDBCol sm="4" xs="12">
                                <SingleSelect
                                    label="Periodicidade"
                                    options={periodicidades}
                                    id="data"
                                    name="data"
                                    onChange={handlePeriodicidade}
                                    value={form.periodicidade}
                                />
                            </MDBCol>
                            <MDBCol sm="4" xs="12">
                                <MDBInput
                                    label="Data Expiração"
                                    type="date"
                                    id="data_expiracao"
                                    onChange={handleForm}
                                    containerClass={errors.data_expiracao ? "invalid-input" : ""}
                                    value={form.data_expiracao}
                                />
                            </MDBCol>
                        </MDBRow>
                        <hr/>
                        <MDBRow>
                            <MDBCol className="text-right">
                                <div style={{display: (saving ? 'none' : '')}}>
                                    <CreateButton onClick={handleValidate} />
                                </div>
                                <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                                    <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Salvar
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Insert;
