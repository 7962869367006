import React, {useContext} from 'react';

import { Route, Switch } from 'react-router-dom';

import PublicLayout from "./components/layout/public/PublicLayout";
import PrivateLayout from "./components/layout/private/PrivateLayout";

import AuthContext from "./store/auth";
//import Home from './pages/public/Home/index';
import Login from './pages/public/Login/index';
import Dashboard from './pages/private/Dashboard/index';
//import Cliente from "./pages/private/Cliente";
//import Calculo from "./pages/private/Calculo";
import Usuario from "./pages/private/Usuario"
import Plano from "./pages/private/Plano"
import AdminRole from "./pages/private/AdminRole"
import Admin from "./pages/private/Admin"
import IndiceSalario from "./pages/private/IndiceSalario"
import Sobrevida from "./pages/private/Sobrevida";
import SalarioMinimo from "./pages/private/SalarioMinimo";
import Site from "./pages/private/Site";
import SalarioTeto from "./pages/private/SalarioTeto";
import Categoria from "./pages/private/Categoria";
import Conteudo from "./pages/private/Conteudo";
import PlanoUsuario from "./pages/private/PlanoUsuario";
//import Signup from "./pages/public/Signup";
//import Planos from "./pages/public/Planos";

const Routes = () => {

    const publicRoutes = ['/', '/login', '/planos', '/assinar'];
    const { signed } = useContext(AuthContext);

    const privateRoutes = signed
        ? (
            <PrivateLayout>
                <Route exact path='/dashboard' component={Dashboard}/>
                <Route exact path='/usuarios' component={Usuario}/>
                <Route exact path='/planos-usuario/:userId' component={PlanoUsuario}/>
                <Route exact path='/admin-roles' component={AdminRole}/>
                <Route exact path='/admins' component={Admin}/>
                <Route exact path='/planos' component={Plano}/>
                <Route exact path='/indices-salarios' component={IndiceSalario}/>
                <Route exact path='/sobrevidas' component={Sobrevida}/>
                <Route exact path='/salarios-minimos' component={SalarioMinimo}/>
                <Route exact path='/salarios-tetos' component={SalarioTeto}/>
                <Route exact path='/site' component={Site}/>
                <Route exact path='/categorias' component={Categoria}/>
                <Route exact path='/conteudos' component={Conteudo}/>
            </PrivateLayout>
        )
        : (
            <Route path="/" component={Login}/>
        );

    return(
        <Switch>

            <Route exact path={publicRoutes}>
                <PublicLayout>
                    {/*<Route exact path='/' component={Home}/>*/}
                    <Route exact path='/' component={Login}/>
                </PublicLayout>
            </Route>

            { privateRoutes }

            <Route
                render={function() {
                    return <h1>Página não encontrada</h1>
                }}
            />
        </Switch>
    );
}

export default Routes;
