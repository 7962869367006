import React, {useEffect, useState} from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
    MDBTable, MDBTableBody, MDBTableHead
} from "mdbreact";
import {Link} from "react-router-dom";
import api from "../../../services/api";
import {formatDate} from "../../../utils/date";

const ItemExpirado = (props) => {
    return (
        <tr>
            <td>{props.item.name}</td>
            <td>{props.item.email}</td>
            <td>{props.item.cel}</td>
            <td>{props.item.titulo}</td>
            <td>{formatDate(props.item.data_expiracao)}</td>
        </tr>
    );
}


const PlanosExpirados = () => {

    const [expirados, setexpirados] = useState([]);
    const [loading, setLoading] = useState(false);

    let expiradosElement = null;
    if(expirados.length > 0){
        expiradosElement = expirados.map((item, index) =>{
            return (
                <ItemExpirado key={'dashboard_expirados_'+index} item={item}/>
            );
        })
    }

    useEffect(() => {
        list();
    }, []);

    async function list(search = ''){
        setLoading(true);
        try{
            const result = await api.get('/api/planos-usuarios-expirados');
            console.log(result.data);
            setexpirados(result.data)
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <MDBContainer fluid style={{marginTop: "0.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <MDBCardTitle>Planos Expirados</MDBCardTitle>
                    <hr/>
                    <MDBTable responsive>
                        <MDBTableHead>
                            <tr>
                                <th>Usuário</th>
                                <th>E-mail</th>
                                <th>Cel</th>
                                <th>Plano</th>
                                <th>Expirado em</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {expiradosElement}
                        </MDBTableBody>
                    </MDBTable>

                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default PlanosExpirados;