import React, {useEffect, useState} from 'react';
import {MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import './style.css';
import Recursos from "./recursos";
import api from "../../../services/api";
import Notify from "../../../components/Notify";
import SaveSmallButton from "../../../components/Buttons/SaveSmallButton";

const Home = () => {

    const [textoPrincipal, setTextoPrincipal] = useState({
        id: 0,
        titulo: ''
    });
    const [texto1, setTexto1] = useState({
        id: 0,
        titulo: '',
        texto: ''
    });
    const [texto2, setTexto2] = useState({
        id: 0,
        titulo: '',
        texto: ''
    });
    const [notify, setNotify] = useState({type:null, text:null});
    const [savingTextoPrincipal, setSavingTextoPrincipal] = useState(false);
    const [savingTexto1, setSavingTexto1] = useState(false);
    const [savingTexto2, setSavingTexto2] = useState(false);
    const [loadingTextoPrincipal, setLoadingTextoPrincipal] = useState(false);
    const [loadingTexto1, setLoadingTexto1] = useState(false);
    const [loadingTexto2, setLoadingTexto2] = useState(false);

    useEffect(() => {
        get('texto-principal-home');
        get('texto1-home');
        get('texto2-home');
    }, []);

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }

    function handleTextoPrincipal(event){
        const { value, id } = event.target;
        setTextoPrincipal({
            ...textoPrincipal,
            titulo: value
        });
    }

    function handleTitulo1(event){
        const { value, id } = event.target;
        setTexto1({
            ...texto1,
            titulo: value
        });
    }

    function handleTitulo2(event){
        const { value, id } = event.target;
        setTexto2({
            ...texto2,
            titulo: value
        });
    }

    function handleTexto1(event){
        const { value, id } = event.target;
        setTexto1({
            ...texto1,
            texto: value
        });
    }

    function handleTexto2(event){
        const { value, id } = event.target;
        setTexto2({
            ...texto2,
            texto: value
        });
    }

    async function get(slug){
        if(slug === 'texto-principal-home'){
            setLoadingTextoPrincipal(true);
        }
        if(slug === 'texto1-home'){
            setLoadingTexto1(true);
        }
        if(slug === 'texto2-home'){
            setLoadingTexto2(true);
        }
        try{
            const result = await api.get('/api/site-texto-slug/'+slug);
            console.log(result.data);
            if(slug === 'texto-principal-home'){
                setTextoPrincipal({
                    id: result.data.id,
                    titulo: result.data.titulo
                });
                setLoadingTextoPrincipal(false);
            }
            if(slug === 'texto1-home'){
                setTexto1({
                    id: result.data.id,
                    titulo: result.data.titulo,
                    texto: result.data.texto
                });
                setLoadingTexto1(false);
            }
            if(slug === 'texto2-home'){
                setTexto2({
                    id: result.data.id,
                    titulo: result.data.titulo,
                    texto: result.data.texto
                });
                setLoadingTexto2(false);
            }
        }catch (error){
            console.log(error);
            if(slug === 'texto-principal-home'){
                setLoadingTextoPrincipal(false);
            }
            if(slug === 'texto1-home'){
                setLoadingTexto1(false);
            }
            if(slug === 'texto2-home'){
                setLoadingTexto2(false);
            }
            handleNotify({type: 'error', text: ''});
        }
    }

    async function save(data){
        console.log(data);
        if(data.slug === 'texto-principal-home'){
            setSavingTextoPrincipal(true);
        }
        if(data.slug === 'texto1-home'){
            setSavingTexto1(true);
        }
        if(data.slug === 'texto2-home'){
            setSavingTexto2(true);
        }
        try{
            const result = await api.put('/api/site-texto/'+data.id, {siteTexto: data});
            console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            if(data.slug === 'texto-principal-home'){
                setSavingTextoPrincipal(false);
            }
            if(data.slug === 'texto1-home'){
                setSavingTexto1(false);
            }
            if(data.slug === 'texto2-home'){
                setSavingTexto2(false);
            }
            handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            if(data.slug === 'texto-principal-home'){
                setSavingTextoPrincipal(false);
            }
            if(data.slug === 'texto1-home'){
                setSavingTexto1(false);
            }
            if(data.slug === 'texto2-home'){
                setSavingTexto2(false);
            }
            handleNotify({type: 'error', text: ''});
        }
    }



    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <Notify notify={notify} />
                    <MDBContainer fluid style={{marginTop: "1.5rem", border: "solid 1px #ccc", padding: '10px'}}>
                        <MDBRow>
                            <MDBCol>
                                <div className="header">&nbsp;</div>
                            </MDBCol>
                        </MDBRow>
                        <br/><br/><br/>
                        <MDBRow>
                            <MDBCol className="text-right">
                                <textarea
                                    className="h1"
                                    style={{width: '80%'}}
                                    rows="3"
                                    placeholder="Texto principal"
                                    onChange={handleTextoPrincipal}
                                    defaultValue={textoPrincipal.titulo}
                                />
                                <br/>
                                <SaveSmallButton saving={savingTextoPrincipal} onClick={() => save({
                                    id: textoPrincipal.id,
                                    titulo: textoPrincipal.titulo,
                                    slug: 'texto-principal-home'
                                }, 'texto-principal-home')}/>
                            </MDBCol>
                            <MDBCol>&nbsp;</MDBCol>
                        </MDBRow>
                        <br/><br/><br/><br/>
                        <div style={{padding: '0 30px'}}>
                            <MDBRow>
                                <MDBCol className="text-right">
                                    <input
                                        className="h2 text-center"
                                        style={{width: '100%'}}
                                        type="text"
                                        placeholder="Titulo"
                                        onChange={handleTitulo1}
                                        defaultValue={texto1.titulo}
                                    />
                                    <br/>
                                    <textarea
                                        className="text text-center"
                                        style={{width: '100%'}}
                                        rows="3"
                                        placeholder="texto"
                                        onChange={handleTexto1}
                                        defaultValue={texto1.texto}
                                    />
                                    <br/>
                                    <SaveSmallButton saving={savingTexto1} onClick={() => save({
                                        id: texto1.id,
                                        titulo: texto1.titulo,
                                        texto: texto1.texto,
                                        slug: 'texto1-home'
                                    }, 'texto1-home')}/>
                                </MDBCol>
                            </MDBRow>
                            <br/><br/><br/>
                            <Recursos/>
                            <br/><br/><br/><br/>
                            <MDBRow>
                                <MDBCol className="text-right">
                                    <input
                                        className="h2"
                                        style={{width: '100%'}}
                                        type="text"
                                        placeholder="Titulo"
                                        onChange={handleTitulo2}
                                        defaultValue={texto2.titulo}
                                    />
                                    <br/>
                                    <textarea
                                        className="text"
                                        style={{width: '100%'}}
                                        rows="3"
                                        placeholder="texto"
                                        onChange={handleTexto2}
                                        defaultValue={texto2.texto}
                                    />
                                    <br/>
                                    <SaveSmallButton saving={savingTexto2} onClick={() => save({
                                        id: texto2.id,
                                        titulo: texto2.titulo,
                                        texto: texto2.texto,
                                        slug: 'texto2-home'
                                    }, 'texto2-home')}/>
                                </MDBCol>
                                <MDBCol>&nbsp;</MDBCol>
                            </MDBRow>
                            <br/><br/><br/>
                        </div>

                    </MDBContainer>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Home;